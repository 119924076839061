import * as React from "react"

import { SEO } from "../components/seo"
import { PrimaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import { clearSnappy } from "../model/helpers"

const Sponsored = () => (
  <>
    {clearSnappy()}
    <PrimaryHeader />
    <SEO isQuiz={true} title="Sponsored" url="sponsored" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="body-wrap"></div>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

export default Sponsored
